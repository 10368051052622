import * as config from '@/config.json'

import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import moment from 'moment'
import LoadingComp from '../../../components/LoadingComp.vue'
import * as functions from '../../../functions/functions'

export default {
  name: 'OrdersVsAccessDevices',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  data: () => {
    return {
      pagination: {},
      paginationRange: 6,
      currentPage: 1,
      perPage: 12,
      token: null,
      items: [],
      fields: [
        {
          key: 'siteName',
          label: 'Loja'
        },
        {
          key: 'date',
          label: 'Data'
        },
        {
          key: 'operation',
          label: 'Operação'
        },
        {
          key: 'customerName',
          label: 'Cliente'
        },
        {
          key: 'customerPhone',
          label: 'Telefone'
        },
        {
          key: 'orderId',
          label: 'ID Venda'
        },
        {
          key: 'totalOrderValue',
          label: 'Total Venda'
        },
        {
          key: 'product',
          label: 'Produto'
        },
        {
          key: 'totalItemValue',
          label: 'Total Item'
        }
      ],
      selectedOption: 2,
      options: [
        {
          value: 1,
          text: 'Ontem'
        },
        {
          value: 2,
          text: 'Hoje'
        },
        {
          value: 3,
          text: 'Últimos 7 dias'
        },
        {
          value: 4,
          text: 'Últimos 15 dias'
        },
        {
          value: 5,
          text: 'Últimos 30 dias'
        },
        {
          value: 6,
          text: 'Com Intervalo de Datas'
        }
      ],

      isLoading: false,
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      typeUser: null,
      qtdOrderPayments: 0,
      pagePagination: null,
      customerName: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      totalPayments: 0,
      quantityPayments: 0,
      averagePayments: 0,

      isBusy: false
    }
  },
  methods: {
    redirect(ref) {
      if (ref == 'Home') {
        this.$router.push(`/home`)
      }
    },

    async navigate(page) {
      await this.getOrdersVsAccessDevices(page)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value
            this.getSitesByCompany(this.selectedOptionCompany)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`

      this.selectedOptionSite = null

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.sites = response.data

          this.optionsSite = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsSite.length == 1) {
            this.selectedOptionSite = this.optionsSite[0].value
            await this.getOrdersVsAccessDevices(this.currentPage)
          }

          if (this.optionsSite.length > 1 && this.typeUser != 'site') {
            await this.getOrdersVsAccessDevices(this.currentPage)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsSite = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }
        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    },

    focus(id) {
      if (id === 'filter') {
        this.filter = ''
      }
      if (document.getElementById(id)) {
        document.getElementById(id).focus()
      }
    },

    async clearSite() {
      this.selectedOptionSite = null
      await this.getOrdersVsAccessDevices(this.currentPage)
    },

    async clearCustomer() {
      this.customerName = ''
      await this.getOrdersVsAccessDevices(this.currentPage)
    },

    async exportToXLSX(page) {
      this.isLoading = true

      const url = `${config.default.urlBase}/sites/orders-vs-access-devices/export-to-xlsx`
      const data = this.buildData(
        this.selectedOptionCompany,
        this.selectedOptionSite,
        this.selectedOption,
        this.startDate,
        this.endDate,
        this.customerName,
        page,
        this.perPage,
        true
      )

      if (data != null) {
        try {
          const response = await axios({
            url,
            method: 'post',
            data,
            headers: {
              authorization: `Bearer ${this.token}`
            }
          })

          if (response.status == 200) {
            this.isLoading = false

            await functions.openFile(response.data.url)
          }
        } catch (error) {
          console.log({
            error
          })

          this.isLoading = false

          if (!error.response) {
            await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
          }

          if (error.response.status == 403) {
            await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
            this.$router.push('login')
          } else if (error.response.status == 404) {
            this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
          } else {
            await this.$alert(`Ocorreu um erro ao exportar o relatório! ${error.response.data.message}`, 'Aviso', 'warning')
          }
        }
      }
    },

    async getOrdersVsAccessDevices(page) {
      this.isBusy = true

      try {
        const url = `${config.default.urlBase}/sites/orders-vs-access-devices/search`
        const data = this.buildData(
          this.selectedOptionCompany,
          this.selectedOptionSite,
          this.selectedOption,
          this.startDate,
          this.endDate,
          this.customerName,
          page,
          this.perPage,
          false
        )
        const response = await axios({
          url,
          method: 'post',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        console.log({ response })

        if (response.status == 200) {
          this.isBusy = false

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.items = response.data.items
          this.totalPayments = response.data.totalPayments
          this.quantityPayments = response.data.quantityPayments
          this.averagePayments = response.data.averagePayments

          this.qtdOrderPayments = response.data.records
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false

        this.items = []
        this.pagination = []
        this.qtdOrderPayments = 0

        this.totalCost = 0
        this.totalQuantity = 0
        this.totalValue = 0
        this.totalProfit = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os itens das vendas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    buildData(companyId, siteId, selectedOption, startDate, endDate, customerName, page, pageSize, exportToXLSX) {
      if (this.selectedOptionCompany != null || this.selectedOptionSite != null) {
        let params = new Object()

        if (companyId != null) {
          params.companyId = companyId
        }

        if (siteId != null) {
          params.siteId = siteId
        }

        if (selectedOption == 1) {
          params.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 2) {
          params.startDate = moment().format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 3) {
          params.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 4) {
          params.startDate = moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 5) {
          params.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 6) {
          if (startDate != null && endDate != null) {
            params.startDate = moment(startDate).format('YYYY-MM-DD 00:00:00')
            params.endDate = moment(endDate).format('YYYY-MM-DD 23:59:59')
          }
        }

        if (customerName != '') {
          params.customerName = customerName
        }

        if (exportToXLSX) {
          params.exportToXLSX = exportToXLSX
        } else {
          params.pageSize = pageSize
          params.page = page
        }

        return params
      } else {
        return null
      }
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    this.typeUser = localStorage.getItem('typeUser')

    await this.getCompanies()

    this.isLoading = false
  }
}
