import { render, staticRenderFns } from "./OrdersVsAccessDevices.html?vue&type=template&id=20fdaa6d&scoped=true&external"
import script from "./OrdersVsAccessDevices.js?vue&type=script&lang=js&external"
export * from "./OrdersVsAccessDevices.js?vue&type=script&lang=js&external"
import style0 from "./OrdersVsAccessDevices.scss?vue&type=style&index=0&id=20fdaa6d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20fdaa6d",
  null
  
)

export default component.exports