import * as config from '@/config.json'

import moment from 'moment/moment'
import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../components/PaginationNav.vue'
import PaginationMobile from '../../components/PaginationMobile.vue'
import LoadingComp from '../../components/LoadingComp.vue'

export default {
  name: 'CustomerList',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  props: ['source'],
  data: () => {
    return {
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 12,
      token: null,
      filter: null,
      customersExcepions: [],
      fieldsCustomersExcepions: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'createdAt',
          label: 'Data'
        },
        {
          key: 'customerName',
          label: 'Cliente'
        },
        {
          key: 'customerDocument',
          label: 'Documento'
        },
        {
          key: 'phoneNumber',
          label: 'Telefone'
        },
        {
          key: 'birthDate',
          label: 'Nascimento'
        },
        {
          key: 'customerEmail',
          label: 'E-mail'
        },
        {
          key: 'deviceSerial',
          label: 'Dispositivo'
        },
        {
          key: 'isBlocked',
          label: 'Bloqueado'
        },
        {
          key: 'reason',
          label: 'Motivo'
        },
        {
          key: 'updatedUserName',
          label: 'Usuário Atualização'
        }
      ],
      selectedOption: null,
      options: [
        {
          value: 1,
          text: 'Nome'
        },
        {
          value: 1,
          text: 'Telefone'
        },
        {
          value: 3,
          text: 'Documento'
        }
      ],
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedOptionDate: 2,
      optionsDate: [
        {
          value: 1,
          text: 'Ontem'
        },
        {
          value: 2,
          text: 'Hoje'
        },
        {
          value: 3,
          text: 'Últimos 7 dias'
        },
        {
          value: 4,
          text: 'Últimos 15 dias'
        },
        {
          value: 5,
          text: 'Últimos 30 dias'
        },
        {
          value: 6,
          text: 'Com Intervalo de Datas'
        }
      ],
      isLoading: false,
      isBusy: false,
      selectedOptionApp: null,
      optionsApp: [],
      selectedOptionBlocked: null,
      optionBlocked: [],
      selectedOptionBlockReason: null,
      optionBlockReason: [],
      typeUser: null,
      totalRows: 0,
      totalRecords: 0,
      selectedRadioCustomerBlock: 0,
      optionsSelectCustomerBlock: [
        {
          value: 0,
          text: 'Todos'
        },
        {
          value: 1,
          text: 'Bloqueados'
        },
        {
          value: 2,
          text: 'Desbloqueados'
        }
      ],
      userId: null,
      userName: ''
    }
  },
  methods: {
    redirect(ref) {
      this.$router.push(ref)
    },

    async buildCustomersExceptionsList(appIds, option, status, selectedOptionDate, page, pageSize) {
      const data = new Object()

      const appIdsArray = new Array()
      appIdsArray.push(appIds)

      const appIdsSelected = new Array(appIdsArray.join(','))
      data.appsId = appIdsSelected

      if (option == 1) {
        data.customerName = this.filter
      }

      if (option == 2) {
        data.phone = this.filter
      }

      if (option == 3) {
        data.customerDocument = this.filter
      }

      if (status == 1) {
        data.isBlocked = true
      }

      if (status == 2) {
        data.isBlocked = false
      }

      if (selectedOptionDate == 1) {
        data.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 2) {
        data.startDate = moment().format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 3) {
        data.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 4) {
        data.startDate = moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 5) {
        data.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 6) {
        if (this.startDate != null && this.endDate != null) {
          data.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00')
          data.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
        }
      }

      data.page = page
      data.pageSize = pageSize

      return data
    },

    async getApps() {
      const url = `${config.default.urlBase}/apps/user/v1`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsApp = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          )

          if (this.optionsApp.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApp[0].value
            }
          }

          if (this.selectedOptionApp) {
            if (!this.selectedPageCustomer) {
              await this.searchCustomersExceptionsList(this.currentPage)
            } else {
              await this.navigate(this.selectedPageCustomer)
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsApp = []

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async updateCustomerBlock(item) {
      const res = await this.$swal({
        title: 'Atualização',
        text: `Deseja atualizar a lista de exceções do cliente: ${item.customerName}?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#303030',
        cancelButtonColor: '#fe5c3a',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      })

      if (res.value) {
        await this.updateCustomersExceptionsList(item)
      } else {
        await this.searchCustomersExceptionsList(this.currentPage)
      }
    },

    async updateCustomersExceptionsList(item) {
      try {
        this.isLoading = true
        const id = item.id

        const url = `${config.default.urlBase}/customers/exceptions-list/${id}/update`
        const dataUpdate = this.buildDataUpdateCustomersExceptionsList(item, this.userId, this.userName)

        const response = await axios({
          url,
          method: 'PATCH',
          data: dataUpdate,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          await this.getNotification('Sucesso', 'Lista de exceções atualizada!', 'success')

          await this.searchCustomersExceptionsList(this.currentPage)
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading
      }
    },

    buildDataUpdateCustomersExceptionsList(item, userId, userName) {
      const data = new Object()

      data.isBlocked = item.isBlocked
      data.userId = userId
      data.userName = userName

      return data
    },

    async searchCustomersExceptionsList(page) {
      this.isBusy = true

      const url = `${config.default.urlBase}/customers/exceptions-list/search`
      const data = await this.buildCustomersExceptionsList(
        this.selectedOptionApp,
        this.selectedOption,
        this.selectedRadioCustomerBlock,
        this.selectedOptionDate,
        page,
        this.perPage
      )

      try {
        const response = await axios({
          url,
          method: 'POST',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusy = false

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.customersExcepions = response.data.items
          this.totalRows = response.data.records
          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false

        this.customersExcepions = []
        this.pagination = []
        this.totalRecords = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }

        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }

        if (error.response.status == 500) {
          await this.$alert(`Ocorreu um erro ao buscar os tipos de eventos!`, 'Erro', 'error')
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async cleanSearch() {
      this.filter = ''

      await this.searchCustomersExceptionsList(this.currentPage)
    },

    async cleanBlockedReason() {
      this.selectedOptionBlocked = null

      await this.searchCustomersExceptionsList(this.currentPage)
    },

    async navigate(page) {
      await this.searchCustomersExceptionsList(page)
    },

    focus(id) {
      document.getElementById(id).focus()
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')

    this.userId = parseInt(localStorage.getItem('idUsuarioLogado'))
    this.userName = localStorage.getItem('usuarioLogado')

    await this.getApps()
    this.isLoading = false
  }
}
