import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import LoadingComp from '../../../components/LoadingComp.vue'
import moment from 'moment'

import * as functions from '../../../functions/functions'
import * as config from '@/config.json'

export default {
  name: 'DocumentsAndItems',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  props: ['source'],
  data: () => {
    return {
      documentId: null,
      token: null,
      isLoading: false,
      isBusy: false,
      isBusyItemsDocuments: false,
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 5,
      totalRecords: 0,
      paginationItemsDocuments: {},
      paginationRangeItemsDocuments: 4,
      currentPageItemsDocuments: 1,
      perPageItemsDocuments: 3,
      totalRecordsItemsDocuments: 0,
      itemsTotals: [],
      fieldsItemsTotals: [
        {
          key: 'reason',
          label: 'Motivo'
        },
        {
          key: 'type',
          label: 'Transação'
        },
        {
          key: 'totalQuantity',
          label: 'Quantidade'
        },
        {
          key: 'totalCostAdjust',
          label: 'Total Custo'
        },
        {
          key: 'totalSaleAdjust',
          label: 'Total Venda'
        }
      ],
      documents: [],
      fieldsDocuments: [
        {
          key: 'details',
          label: ''
        },
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'createdAt',
          label: 'Data'
        },
        {
          key: 'reason',
          label: 'Motivo'
        },
        {
          key: 'reasonType',
          label: 'Transação'
        },
        {
          key: 'siteName',
          label: 'Loja'
        },
        {
          key: 'userName',
          label: 'Usuário'
        },
        {
          key: 'totalQuantity',
          label: 'Quantidade'
        },
        {
          key: 'totalCost',
          label: 'Custo'
        },
        {
          key: 'totalValue',
          label: 'Valor'
        },
        {
          key: 'export',
          label: ''
        }
      ],
      itemsDocuments: [],
      fieldsItemsDocuments: [
        {
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'unity',
          label: 'Unidade'
        },
        {
          key: 'quantity',
          label: 'Quantidade'
        },
        {
          key: 'isBlockedUnderEighteen',
          label: 'Classificação'
        }
      ],
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      selectedOptionReason: null,
      optionsReason: [],
      stockAdjustmentDocument: [],
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedOptionDate: 2,
      optionsDate: [
        {
          value: 1,
          text: 'Ontem'
        },
        {
          value: 2,
          text: 'Hoje'
        },
        {
          value: 3,
          text: 'Últimos 7 dias'
        },
        {
          value: 4,
          text: 'Últimos 15 dias'
        },
        {
          value: 5,
          text: 'Últimos 30 dias'
        },
        {
          value: 6,
          text: 'Com Intervalo de Datas'
        }
      ]
    }
  },

  methods: {
    redirect(ref) {
      this.$router.push(ref)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async clearCompanyFilter() {
      this.selectedOptionCompany = null
      await this.searchStockAdjustment(this.currentPage)
    },

    async clearSiteFilter() {
      this.selectedOptionSite = null
      await this.searchStockAdjustment(this.currentPage)
    },

    async clearReasonFilter() {
      this.selectedOptionReason = null
      await this.searchStockAdjustment(this.currentPage)
    },

    navigate(page) {
      this.searchStockAdjustment(page)
    },

    navigateItemsDocuments(page) {
      this.getItemsDocuments(this.documentId, page)
    },

    async handleButtonClick(row) {
      this.documentId = row.item.id
      // await row.toggleDetails()
      await this.getItemsDocuments(this.documentId, this.currentPageItemsDocuments)
    },

    async exportToXLSX(row) {
      try {
        this.isLoading = true

        this.stockAdjustmentDocument = row.item.id

        const url = `${config.default.urlBase}/stock-adjustment/document-and-items/${this.stockAdjustmentDocument}/export-to-xlsx`
        const params = this.buildParamsGetItemsDocuments(
          this.selectedOptionCompany,
          this.selectedOptionDate,
          this.currentPageItemsDocuments,
          this.perPageItemsDocuments,
          true
        )

        try {
          const response = await axios({
            url,
            method: 'get',
            params,
            headers: {
              authorization: `Bearer ${this.token}`
            }
          })

          if (response.status == 200) {
            this.isLoading = false

            await functions.openFile(response.data.url)
          }
        } catch (error) {
          console.log({
            error
          })

          this.isLoading = false

          if (!error.response) {
            await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
          }

          if (error.response.status == 403) {
            await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
            this.$router.push('login')
          } else if (error.response.status == 404) {
            this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
          } else {
            await this.$alert(`Ocorreu um erro ao exportar o relatório! ${error.response.data.message}`, 'Aviso', 'warning')
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false
      }
    },

    buildParamsGetItemsDocuments(companyId, selectedOptionDate, page, pageSize, exportToXLSX) {
      let params = new Object()

      params.companyId = companyId
      if (selectedOptionDate == 1) {
        params.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
        params.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 2) {
        params.startDate = moment().format('YYYY-MM-DD 00:00:00')
        params.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 3) {
        params.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00')
        params.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 4) {
        params.startDate = moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:00')
        params.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 5) {
        params.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
        params.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 6) {
        if (this.startDate != null && this.endDate != null) {
          params.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00')
          params.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
        }
      }

      if (exportToXLSX) {
        params.exportToXLSX = exportToXLSX
      } else {
        params.pageSize = pageSize
        params.page = page
      }

      return params
    },

    async getItemsDocuments(id, page) {
      this.itemsDocuments = []
      try {
        this.isBusyItemsDocuments = true

        const url = `${config.default.urlBase}/stock-adjustment/documents/${id}/items`
        const params = this.buildParamsGetItemsDocuments(this.selectedOptionCompany, this.selectedOptionDate, page, this.perPageItemsDocuments, false)

        const response = await axios({
          url,
          method: 'get',
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.itemsDocuments = response.data.items

          this.paginationItemsDocuments = response.data
          this.paginationItemsDocuments.rowsPerPage = this.perPageItemsDocuments
          this.paginationItemsDocuments.paginationRange = this.paginationRangeItemsDocuments
          this.totalRecordsItemsDocuments = response.data.records

          this.isBusyItemsDocuments = false
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusyItemsDocuments = false
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        this.optionsSite = []
        this.selectedOptionSite = null

        if (response.status == 200) {
          this.optionsSite = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.name}`
              }
            })
          )

          if (this.optionsSite.length == 1) {
            this.selectedOptionSite = this.optionsSite[0].value
          }

          await this.searchStockAdjustment(this.currentPage)
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsSite = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }
        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    },

    async getReasons() {
      try {
        const url = `${config.default.urlBase}/stock-adjustment`

        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsReason = await Promise.all(
            response.data.reasons.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.description} - [${item.type}]`
              }
            })
          )
        }

        if (this.optionsReason.length == 1) {
          this.selectedOptionReason = this.optionsReason[0].value
        }
      } catch (error) {
        console.log({
          error
        })
      }
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        this.selectedOptionCompany = null
        this.optionsCompany = []

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value
            }

            this.getSitesByCompany(this.selectedOptionCompany)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    buildDataStockAdjustment(companyId, siteId, reasonId, selectedOptionDate, page, pageSize) {
      let data = new Object()

      if (companyId) {
        data.companyId = companyId
      }

      if (siteId) {
        data.siteId = siteId
      }

      if (reasonId) {
        data.reasonId = reasonId
      }

      if (selectedOptionDate == 1) {
        data.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 2) {
        data.startDate = moment().format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 3) {
        data.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 4) {
        data.startDate = moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 5) {
        data.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
        data.endDate = moment().format('YYYY-MM-DD 23:59:59')
      }

      if (selectedOptionDate == 6) {
        if (this.startDate != null && this.endDate != null) {
          data.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00')
          data.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
        }
      }

      data.page = page
      data.pageSize = pageSize

      return data
    },

    async searchStockAdjustment(page) {
      this.documents = []
      this.itemsDocuments = []

      try {
        this.isBusy = true

        const url = `${config.default.urlBase}/stock-adjustment/documents`

        const data = this.buildDataStockAdjustment(
          this.selectedOptionCompany,
          this.selectedOptionSite,
          this.selectedOptionReason,
          this.selectedOptionDate,
          page,
          this.perPage
        )

        const response = await axios({
          url,
          method: 'POST',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.documents = response.data.items
          this.itemsTotals = response.data.totals

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange
          this.totalRecords = response.data.records

          this.isBusy = false
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false

        this.documents = []
        this.itemsTotals = []
        this.totalRecords = 0
        this.pagination = {}

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }

        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    },

    focus(id) {
      document.getElementById(id).focus()
    }
  },

  computed: {
    rows() {
      return this.totalRecordsItemsDocuments
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')

    await this.getCompanies()
    await this.getReasons()

    this.isLoading = false
  }
}
